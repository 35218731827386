import { Container } from "react-bootstrap";
const About = () => {
  return (
    <Container>
      <main className="text-center mt-4 mb-3">
        <h1>About Me</h1>
      </main>
    </Container>
  );
};
export default About;
